<template>
  <div>
    <label-ref :id.sync="itemId" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    LabelRef: () => import('@/components/documents-ref/label-ref')
  },
  computed: {
    iconProps () {
      return { small: true }
    }
  },
  props: {
    itemId: Number,
    value: {
      type: String
    }
  }
}
</script>
